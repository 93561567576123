import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router ({
    // mode: 'history',
    routes: [
        {
            path: "/",
            name: 'medal',
            component: () => import('../view/3D_medal/3D.vue')
        }
    ],
})


export function resetRouter() {
    const newRouter = router;
    router.matcher = newRouter.matcher; // reset router
}

export default router;